<template>
    <div style="height: 100%; overflow-y: scroll">
        <div style="display: flex; min-width: 1280px; overflow-x: hidden">
            <div style="background: #f9f9f9; flex: 1; min-width: 640px">
                <Card style="margin: 8px">
                    <Row slot="title" style="font-size: 22px; height: 45px; color: #19be6b">
                        <Col span="16" style="">
                            <span style="line-height: 100%; font-size: 22px; font-weight: bold">
                                {{ user }}{{ buttonRoot ? (buttonRoot == "1001" ? "（编辑）" : buttonRoot == "1002" ? "（审核）" : buttonRoot == "1003" ? "（发布）" : "") : "" }}
                            </span>
                            <span style="line-height: 150%; font-size: 20px; color: #333">{{ time }}</span>

                            <!-- <span style="line-height: 150%; font-size: 25px; font-weight: bold" v-if="buttonRoot == '1003'">
                                <br />
                                当前平台在线人数:{{ platfromOnlineUserNum }}
                            </span> -->
                        </Col>
                        <Col span="8" style="text-align: right">
                            <Dropdown style="padding: 0px" v-if="orgList != null && orgList.length > 1">
                                <Button type="success" size="large">
                                    切换机构
                                    <Icon type="ios-arrow-down"></Icon>
                                </Button>
                                <DropdownMenu slot="list">
                                    <template v-for="org in orgList">
                                        <DropdownItem :key="org.orgCode" v-if="org.orgCode != orgCode">
                                            <div @click="gotoOrg(org)" style="font-size: 16px">{{ org.orgName }}</div>
                                        </DropdownItem>
                                    </template>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>

                    <div style="height: 50px; text-align: right; font-size: 18px" v-html="sysnote"></div>
                </Card>

                <Card style="margin: 8px">
                    <div slot="title" style="display: flex; justify-content: space-between; align-items: center">
                        <p style="font-size: 20px; line-height: 42px; height: 42px; color: #333">常用功能</p>
                        <Button type="info" v-if="orgCat && orgCat.length && orgCat.indexOf('1') > -1" @click="pushContract()" style="padding: 0 15px; margin-right: 20px">推送率</Button>
                        <Button type="info" v-if="[2, 3, 12].indexOf(dimensionId) > -1" @click="pushQrcode" style="padding: 0 15px; margin-right: 20px">邀请码</Button>
                        <Button v-if="[2, 3, 12].indexOf(dimensionId) > -1" type="info" @click="$refs.weekly_reports_published.display()" style="padding: 0 15px">周报</Button>
                    </div>

                    <div style="text-align: center; font-size: 18px; display: flex; width: 100%; overflow: hidden">
                        <Col span="24" style="text-align: center" v-if="commonBrowseList == null || commonBrowseList.length == 0">
                            <Icon type="ios-alert" />
                            暂时没有常用功能
                        </Col>

                        <Card style="width: 120px; margin: 5px" v-for="cb in commonBrowseList" :key="cb.id" class="userItem">
                            <div style="text-align: center">
                                <a style="color: #555" v-if="cb.appCode && cb.appCode == 'suiyue'" @click.prevent="changeOpen(cb.url)">
                                    <img :src="cb.icon" style="width: 50px; height: 50px; margin: 5px" onerror="this.src='/images/noicon.png';" />
                                    <h4 style="font-size: 12px">{{ cb.name | spliceTitle }}</h4>
                                </a>
                                <a :href="cb.url" style="color: #555" v-else>
                                    <img :src="cb.icon" style="width: 50px; height: 50px; margin: 5px" onerror="this.src='/images/noicon.png';" />
                                    <h4 style="font-size: 12px">{{ cb.name | spliceTitle }}</h4>
                                </a>
                            </div>
                        </Card>
                    </div>
                </Card>

                <Card class="todo-box" v-if="dimensionId == 2" style="margin: 8px">
                    <div class="head-box">
                        <p class="title">待办提醒</p>
                    </div>
                    <div class="item-box">
                        <div class="item" v-if="clumnNum" @click="getClumnList('')">
                            <p class="name">空白栏目</p>
                            <p class="value">( {{ clumnNum }} )</p>
                        </div>
                        <div class="item" v-if="resetNum" @click="getClumnList('name')">
                            <p class="name">居民提醒更新</p>
                            <p class="value">( {{ resetNum }} )</p>
                        </div>
                        <div class="item" v-if="userAuthCount" @click="$router.push('/verified?id=' + projectId)">
                            <p class="name">实名审核</p>
                            <p class="value">( {{ userAuthCount }} )</p>
                        </div>
                        <div class="item" v-if="supplementRoomNumber" @click="$router.push('/supplement?communityCode=' + communityCode)">
                            <p class="name">补充房号</p>
                            <p class="value">( {{ supplementRoomNumber }} )</p>
                        </div>
                        <div class="item" v-if="homeAudit" @click="$router.push('/linehomemanage?communityCode=' + communityCode)">
                            <p class="name">网上家园审核</p>
                            <p class="value">( {{ homeAudit }} )</p>
                        </div>
                        <div class="item" v-if="agendaroomAdvice" @click="$router.push('/agendaroomopinion')">
                            <p class="name">议事厅意见处理</p>
                            <p class="value">( {{ agendaroomAdvice }} )</p>
                        </div>
                        <div class="item" v-if="agendaroomCommons" @click="$router.push('/agendaroomcomment')">
                            <p class="name">议事厅评论审核</p>
                            <p class="value">( {{ agendaroomCommons }} )</p>
                        </div>
                        <div class="empty">
                            <Icon type="ios-checkmark-circle" />
                            <span>暂无待办</span>
                        </div>
                    </div>
                </Card>

                <Card style="margin: 8px">
                    <div class="select-view">
                        <div :class="activeIndex == index ? 'select-item active' : 'select-item'" v-for="(item, index) in selectList" :key="index" @click="changeIndex(index)">
                            {{ item.name }}
                            <span class="span-select">{{ `( ${item.num} ) ` || 0 }}</span>
                        </div>
                    </div>
                    <!-- <p slot="title" style="font-size: 20px; line-height: 30px; height: 30px; color: #333">工作待办</p> -->

                    <div style="min-height: 300px; font-size: 16px; padding-top: 0px">
                        <List v-if="activeIndex == 0">
                            <template v-if="todoList == null || todoList.length == 0">
                                <ListItem>
                                    <Col span="24" style="text-align: center">
                                        <Icon type="ios-alert" />
                                        暂时没有工作待办
                                    </Col>
                                </ListItem>
                            </template>
                            <template v-else>
                                <ListItem v-for="todo in todoList" :key="todo.id">
                                    <Col span="5" style="overflow: hidden">
                                        <Button type="dashed" @click="jumpEdit(todo)">
                                            <!-- <a :href="todo.appCode == 'suiyue' ? '/#' + todo.url : todo.url" style="color:#19be6b;">{{ todo.menuName }}</a> -->
                                            <a style="color: #19be6b">{{ todo.menuName }}</a>
                                        </Button>
                                    </Col>
                                    <Col span="15">{{ todo.title }}</Col>
                                    <Col span="3" style="text-align: right">
                                        <!-- <Button type="warning" style="min-width:60px;max-width:100%;" @click="openEdit(todo.appCode == 'suiyue' ? '/#' + todo.link : todo.link, todo.title)">
                                            {{ todo.status }}
                                        </Button> -->
                                        <Button type="warning" style="width: 100px" @click="openEditMore(todo)">
                                            {{ todo.status }}
                                        </Button>
                                    </Col>
                                </ListItem>
                            </template>
                        </List>
                        <List v-else-if="activeIndex == 1 || activeIndex == 2">
                            <template v-if="homeList == null || homeList.length == 0">
                                <ListItem>
                                    <Col span="24" style="text-align: center">
                                        <Icon type="ios-alert" />
                                        暂时没有{{ activeIndex == 1 ? "你言我语" : "民呼我为" }}
                                    </Col>
                                </ListItem>
                            </template>
                            <template v-else>
                                <ListItem v-for="todo in homeList" :key="todo.id">
                                    <Col span="5" style="overflow: hidden">
                                        <Button type="dashed" @click="jumpHomeList(todo)">
                                            <a style="color: #19be6b">{{ todo.menuName }}</a>
                                        </Button>
                                    </Col>
                                    <Col span="15">{{ todo.title }}</Col>
                                    <Col span="3" style="text-align: right">
                                        <Button v-if="todo.status && todo.status != '3'" type="warning" style="width: 100px" @click="openHome(todo)">
                                            {{ todo.status == "1" ? "待回复" : todo.status == "2" ? "待审核" : todo.status == "3" ? "已完成" : "" }}
                                        </Button>
                                    </Col>
                                </ListItem>
                            </template>
                        </List>
                        <List v-else-if="activeIndex == 3">
                            <template v-if="replyList == null || replyList.length == 0">
                                <ListItem>
                                    <Col span="24" style="text-align: center">
                                        <Icon type="ios-alert" />
                                        暂时没有我的邮箱
                                    </Col>
                                </ListItem>
                            </template>
                            <template v-else>
                                <ListItem v-for="todo in replyList" :key="todo.id">
                                    <Col span="5" style="overflow: hidden">
                                        <Button type="dashed" @click="jumpWishList(todo)">
                                            <a style="color: #19be6b">{{ todo.menuName }}</a>
                                        </Button>
                                    </Col>
                                    <Col span="15">{{ todo.content }}</Col>
                                    <Col span="3" style="text-align: right">
                                        <Button type="warning" style="width: 100px" @click="openEmail(todo)">
                                            {{ "回复" }}
                                        </Button>
                                    </Col>
                                </ListItem>
                            </template>
                        </List>
                        <List v-else-if="activeIndex == 4">
                            <template v-if="wishList == null || wishList.length == 0">
                                <ListItem>
                                    <Col span="24" style="text-align: center">
                                        <Icon type="ios-alert" />
                                        暂时没有微心愿
                                    </Col>
                                </ListItem>
                            </template>
                            <template v-else>
                                <ListItem v-for="todo in wishList" :key="todo.id">
                                    <Col span="5" style="overflow: hidden">
                                        <Button type="dashed" @click="jumpWishList(todo)">
                                            <a style="color: #19be6b">{{ todo.menuName }}</a>
                                        </Button>
                                    </Col>
                                    <Col span="15">{{ todo.context }}</Col>
                                    <Col span="3" style="text-align: right">
                                        <Button type="warning" style="width: 100px" @click="openWish(todo)">
                                            {{
                                                todo.status == "1"
                                                    ? "待审核"
                                                    : todo.status == "2"
                                                    ? "待帮助"
                                                    : todo.status == "3"
                                                    ? "帮助中"
                                                    : todo.status == "4"
                                                    ? "已完成"
                                                    : todo.status == "5"
                                                    ? "驳回"
                                                    : ""
                                            }}
                                        </Button>
                                    </Col>
                                </ListItem>
                            </template>
                        </List>
                    </div>
                </Card>
            </div>
            <div style="background: #f9f9f9; flex: 1; min-width: 640px">
                <div class="num-title">
                    <span>昨日核心数据 {{ nowTime1 }}</span>
                    <Poptip
                        v-if="dimensionId == 2"
                        word-wrap
                        width="400"
                        content="社区注册访问用户数：登录进入过某个社区随约频道的用户，即为该社区（访问）用户，一个手机号为一个用户；
社区注册用户数：社区居民数据库中登记了手机号且登录过随约的居民，或者自主入驻网上家园并通过审核的居民，同一个人有多套房算多个注册用户；
社区信息发布总数：所有服务主体或机构发布到该社区的资讯活动等内容总数；
引入服务主体数：辖区单位、商家、机构等基本信息上传到平台的总数；
商户服务数：在随约入驻成为商家，提供交易服务的商家总数；"
                        trigger="hover"
                        placement="bottom-end"
                    >
                        <div class="hint">
                            <Icon class="icon" type="ios-help-circle-outline" />
                            <p class="name">解释说明</p>
                        </div>
                    </Poptip>
                </div>

                <div class="statistics-box" v-if="dimensionId != 2">
                    <div class="item-box">
                        <p class="title">机构总用户数:</p>
                        <div class="value">
                            <NumberRoll :number="orgUserTotal"></NumberRoll>
                        </div>
                    </div>
                    <div class="item-box">
                        <p class="title">内容发布总数:</p>
                        <div class="value">
                            <NumberRoll :number="orgPublishTotal"></NumberRoll>
                        </div>
                    </div>
                </div>

                <div class="module-box" v-if="dimensionId == 2">
                    <div class="item-box" v-for="(item, idx) in coreModules" :key="idx">
                        <div class="name">{{ item.t }}</div>
                        <div class="increase-box">
                            <div class="item" v-for="(v, i) in item.i" :key="i">
                                <p>{{ v.t }}:</p>
                                <div class="val" :class="{ click: v.c }" :style="v.s" @click="v.c && v.c()">
                                    <NumberRoll :number="(coreData[item.n] ? coreData[item.n][v.n] : 0) || 0"></NumberRoll>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="numList" style="" v-if="dimensionId == 2">
                    <Card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between">
                        <p class="num">{{ userNumData.yesterdayAdd ? userNumData.yesterdayAdd : 0 }}</p>
                        <p class="title">昨日新增</p>
                    </Card>
                    <Card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between">
                        <p class="num">{{ userNumData.weekAdd ? userNumData.weekAdd : 0 }}</p>
                        <p class="title">近7天新增</p>
                    </Card>
                    <Card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between">
                        <p class="num" style="color: rgb(25, 190, 107); text-decoration: underline" @click="openAllUser">{{ userNumData.userTotal ? userNumData.userTotal : 0 }}</p>
                        <p class="title">全部用户</p>
                    </Card>
                    <Card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between; min-width: 161px">
                        <p class="num">
                            <span style="font-size: 16px; font-weight: 500">户数:</span>
                            {{ userNumData.onlineHomeHouse ? userNumData.onlineHomeHouse : 0 }}
                        </p>
                        <p class="num">
                            <span style="font-size: 16px; font-weight: 500">人数:</span>
                            {{ userNumData.yesterdayOnlineHome ? userNumData.yesterdayOnlineHome : 0 }}
                        </p>
                        <p class="title">网上家园</p>
                    </Card>
                    <Card style="height: 100%; display: flex; flex-direction: column; justify-content: space-between; cursor: pointer">
                        <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-between" @click="getClumnList('')">
                            <p class="num" style="color: red; text-decoration: underline">{{ clumnNum }}</p>
                            <p class="title">空白栏目</p>
                        </div>
                        <div style="height: 100%; display: flex; flex-direction: column; justify-content: space-between" @click="getClumnList('name')">
                            <p class="num" style="color: red; text-decoration: underline">{{ resetNum }}</p>
                            <p class="title">居民提醒更新</p>
                        </div>
                    </Card>
                </div> -->

                <!-- 报表功能入口 -->
                <Card class="report-card" style="margin: 8px; margin-left: 0px" v-if="reportList && reportList.length > 0">
                    <div class="head-box">
                        <p class="title">数采服务系统</p>
                        <p class="more" @click="$router.push('/residentdatabasemybusiness?type=DYTX')">查看更多></p>
                    </div>
                    <div class="functions-box">
                        <div
                            class="item-box"
                            v-for="(item, idx) in reportList"
                            :key="idx"
                            @click="
                                $router.push(
                                    `/residentdatabasedatatable?id=${item.appId}&title=${item.appName}&d=false&s=collect&type=DYTX&ti=${item.templateId}&gid=${
                                        (item.customConfig ? item.customConfig.chatGroupId : '') || ''
                                    }&gn=${(item.customConfig ? item.customConfig.chatGroupName : '') || ''}`
                                )
                            "
                        >
                            <p class="name">{{ item.appName }}</p>
                            <Icon type="ios-arrow-forward" />
                        </div>
                        <div class="empty-box" v-if="!reportList || reportList.length <= 0">暂无报表功能数据</div>
                    </div>
                </Card>

                <Card style="margin: 8px; margin-left: 0px">
                    <p slot="title" style="font-size: 20px; line-height: 42px; height: 42px; color: red; position: relative">
                        <img src="../../../public/images/homefire.gif" style="width: 40px" alt="" />
                        近期大家都在开展的工作
                        <span style="margin-left: 10px; font-size: 12px; color: #999999">
                            请
                            <span style="color: red">编辑权限</span>
                            的人员点击引用一键快速编辑
                        </span>
                        <Button style="color: #24c8c8; position: absolute; top: 5px; right: 0" @click="$router.push('/templatemanage')">查看全部</Button>
                    </p>

                    <div style="text-align: center; font-size: 18px; display: flex; flex-wrap: wrap">
                        <Col span="24" style="text-align: center" v-if="tableData.length == 0">
                            <Icon type="ios-alert" />
                            暂时没有模板
                        </Col>
                        <div class="tablediv" v-if="tableData.length != 0">
                            <Table :columns="tableColumns" :data="tableData"></Table>
                        </div>

                        <!-- <Card style="width:125px;margin:5px;" v-for="cb in commonBrowseList" :key="cb.id">
            <div style="text-align:center">
              <a style="color:#555;" v-if="cb.appCode && cb.appCode == 'suiyue'" @click.prevent="changeOpen(cb.url)">
                <img
                  :src="cb.icon"
                  style="width:60px;height:60px;margin:5px;"
                  onerror="this.src='/images/noicon.png';"
                />
                <h4>{{cb.name}}</h4>
              </a>
              <a :href="cb.url" style="color:#555;" v-else>
                <img
                  :src="cb.icon"
                  style="width:60px;height:60px;margin:5px;"
                  onerror="this.src='/images/noicon.png';"
                />
                <h4>{{cb.name}}</h4>
              </a>
              
            </div>
          </Card> -->
                    </div>
                </Card>
            </div>
        </div>

        <!-- 空白栏目清单 -->
        <LiefengModal :title="titleName" :value="listStatus" @input="changeListStatus" :width="700" :fullscreen="true">
            <template v-slot:title>
                <div style="display: flex; justify-content: space-between; height: 30px; line-height: 30px; padding: 5px 10px 0 10px; box-sizing: border-box">
                    <p style="font-size: 20px; font-weight: 700">合计 {{ titleNum }} 个</p>
                    <p>更新时间 {{ nowTime + " 00:00:00" }}</p>
                </div>
            </template>
            <template v-slot:contentarea>
                <LiefengTable :tableData="listData" :fixTable="true" :talbeColumns="listColumns" :hidePage="true"></LiefengTable>
            </template>
            <!-- <template v-slot:toolsbar>
                <Button type="primary" style="marginRight:10px"  @click="listStatus = false">关闭</Button>
            </template> -->
        </LiefengModal>

        <!-- 周报 -->
        <WeeklyReportsPublished ref="weekly_reports_published"></WeeklyReportsPublished>
    </div>
</template>
<script>
//@route('/homepage')
import VeHistogram from "v-charts/lib/histogram.common"
import LiefengModal from "@/components/LiefengModal"
import LiefengTable from "@/components/LiefengTable"

// 周报
import WeeklyReportsPublished from "@/views/weeklyreports/childrens/Published.vue"
import Route from "../communitymobilization/entity/Route"

// 数字滚动
import NumberRoll from "../managsystem/childrens/NumberRoll.vue"

export default {
    name: "homepage",
    components: {
        VeHistogram,
        LiefengModal,
        LiefengTable,
        // 周报
        WeeklyReportsPublished,
        // 数字滚动
        NumberRoll,
    },
    data() {
        return {
            orgId: "", // 存储当前机构的orgId
            listStatus: false,
            dimensionId: "",
            userNumData: {},
            platfromOnlineUserNum: 0,
            orgCode: "",
            user: "",
            time: "",
            sysnote: "",
            orgList: [],
            todoList: [],
            commonBrowseList: [],
            statList: [],
            chartData: {
                columns: ["名称", "数量"],
                rows: [],
            },
            buttonRoot: "",
            tableColumns: [
                {
                    title: "菜单名称",
                    key: "columnName",
                    // align: "center",
                    minWidth: 180,
                    maxWidth: 250,
                    render: (h, params) => {
                        return h(
                            "span",
                            {
                                style: {
                                    dispiay: "inline-block",
                                    backgroundColor: "#D3F4F4",
                                    padding: "20px 10px",
                                    color: "#24C8C8",
                                },
                            },
                            params.row.columnName
                        )
                    },
                },
                {
                    title: "信息内容标题",
                    key: "templateName",
                    minWidth: 300,
                },
                {
                    title: "操作",
                    align: "right",
                    width: 100,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        size: "large",
                                    },
                                    style: {
                                        display: this.buttonRoot == "1001" ? "block" : "none",
                                    },
                                    on: {
                                        click: () => {
                                            this.jumpTemp(params.row)
                                            // this.$router.push(`${params.row.url}?menuId=${params.row.menuCode}&templateId=${params.row.id}`)
                                        },
                                    },
                                },
                                "引用"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            tableHei: "",
            listLoading: false,
            clumnNum: 0,
            nowTime: "",
            listColumns: [
                {
                    title: "栏目路径",
                    key: "columnName",
                    minWidth: 400,
                    align: "center",
                },
            ],
            listData: [],
            // 待办等分类列表
            selectList: [
                {
                    name: "待办",
                    num: 0,
                },
                {
                    name: "你言我语",
                    num: 0,
                },
                {
                    name: "民呼我为",
                    num: 0,
                },
                {
                    name: "我的邮箱",
                    num: 0,
                },
                {
                    name: "微心愿",
                    num: 0,
                },
                {
                    name: "评论审核",
                    num: 0,
                },
            ],
            activeIndex: 0,
            wishList: [], // 微心愿列表
            replyList: [], // 我的邮箱列表
            homeList: [], // 你言我语列表
            nowTime1: "",
            resetNum: 0,
            titleName: "空白栏目清单",
            titleNum: 0,

            // 报表功能入口列表
            reportList: null,

            // 核心数据
            coreData: {},
            // 核心数据模块
            coreModules: [
                {
                    t: "社区注册访问用户数",
                    n: "statRegister",
                    i: [
                        {
                            t: "本月",
                            n: "ofMonth",
                        },
                        {
                            t: "本年",
                            n: "ofYear",
                        },
                        {
                            t: "全部",
                            n: "total",
                            c: () => {
                                Route.jump("/mobilizationregistered")
                            },
                        },
                    ],
                },
                {
                    t: "社区注册用户数",
                    n: "userRegister",
                    i: [
                        {
                            t: "本月",
                            n: "ofMonth",
                        },
                        {
                            t: "本年",
                            n: "ofYear",
                        },
                        {
                            t: "全部",
                            n: "total",
                            c: () => {
                                Route.jump("/residentdatabasetable?active=true")
                            },
                        },
                    ],
                },
                {
                    t: "信息发布总数",
                    n: "orgPublishNum",
                    i: [
                        {
                            t: "本月",
                            n: "ofMonth",
                        },
                        {
                            t: "本年",
                            n: "ofYear",
                        },
                        {
                            t: "全部",
                            n: "total",
                        },
                    ],
                },
                {
                    t: "引入服务主体数",
                    n: "serviceAmount",
                    i: [
                        {
                            t: "本月",
                            n: "ofMonth",
                        },
                        {
                            t: "本年",
                            n: "ofYear",
                        },
                        {
                            t: "全部",
                            n: "total",
                            c: () => {
                                Route.jump("/shopmanagementindex")
                            },
                        },
                    ],
                },
                {
                    t: "商户服务数",
                    n: "companyTrade",
                    i: [
                        {
                            t: "有交易",
                            n: "shopTradesCount",
                        },
                        {
                            t: "全部商户",
                            n: "total",
                        },
                    ],
                },
            ],

            /** 待办提醒 */
            // 网上家园审核
            homeAudit: 0,
            // 补充房号
            supplementRoomNumber: 0,
            // 实名审核统计
            userAuthCount: 0,
            // Project Id
            projectId: null,
            // 社区编码
            communityCode: parent.vue.loginInfo?.userinfo?.communityCode,
            // 议事厅意见处理
            agendaroomAdvice: 0,
            // 议事厅评论审核
            agendaroomCommons: 0,
            // 机构总用户数
            orgUserTotal: 0,
            // 内容发布总数
            orgPublishTotal: 0,

            orgCat: "",
        }
    },
    async created() {
        if (window.sessionStorage.getItem("dimensionId")) {
            window.sessionStorage.removeItem("dimensionId")
        }
        // 获取用户权限
        let userButtonRoot = parent.vue.userButtonRoot
        this.buttonRoot = userButtonRoot.includes("1001") ? "1001" : userButtonRoot.includes("1002") ? "1002" : userButtonRoot.includes("1003") ? "1003" : ""
        this.orgCode = top.vue.loginInfo.userinfo.orgCode
        this.orgList = top.vue.loginInfo.userinfo.orgUserVOS
        this.user = top.vue.loginInfo.userinfo.userDepName
        if (this.orgList && this.orgList.length) {
            this.orgList.map(item => {
                if (item.orgCode == window.sessionStorage.getItem("orgCode")) {
                    window.sessionStorage.setItem("dimensionId", item.dimensionId)
                    this.dimensionId = item.dimensionId
                }
            })
        }
        var date = new Date()
        if (date.getHours() < 3) {
            this.time = "晚上好！"
        } else if (date.getHours() < 11) {
            this.time = "早上好！"
        } else if (date.getHours() < 14) {
            this.time = "中午好！"
        } else if (date.getHours() < 18) {
            this.time = "下午好！"
        } else if (date.getHours() < 23) {
            this.time = "晚上好！"
        }

        // 获取机构统计
        this.getInstitutionalStatistics()

        if (!this.dimensionId || this.dimensionId != "2") {
            this.selectList.splice(3, 4)
            return
        }
        // 我的邮箱
        this.orgId = ""
        parent.vue.loginInfo.userinfo.orgUserVOS.map(item => {
            if (item.orgCode == parent.vue.loginInfo.userinfo.orgCode) this.orgId = item.orgId
        })

        this.orgCat = window.sessionStorage.getItem("orgCat")
        console.log("this.orgCat", this.orgCat)
        await this.listHomeReplyLatest()
        // 微心愿
        await this.listHomeWishHelp()

        // 获取网上家园待审核
        this.getHomeAudit()
        // 获取补充房号
        this.getSupplementRoomNumber()
        // 获取实名审核数量
        this.getUserAuthCount()
        // 获取议事厅待办数据
        this.getAgendaroomChamberCount()
    },
    async mounted() {
        this.orgId = ""
        this.nowTime1 = this.$core.formatDate(new Date(new Date().getTime() - 3600 * 24 * 1000), "yyyy年MM月dd日")
        parent.vue.loginInfo.userinfo.orgUserVOS.map(item => {
            if (item.orgCode == parent.vue.loginInfo.userinfo.orgCode) this.orgId = item.orgId
        })
        this.tableHei = window.innerHeight - 400
        this.getPlatformOnline()
        this.getSysNote()
        this.getTodos()
        this.getCommonBrowse()
        // this.getStat();
        this.getTemplate()
        this.getUserNum()
        this.getOrgColumnPvListLe()

        // 评论审核
        this.getPinglun()
        // 你言我语
        await this.listHomeReply("0001396")
        // 民呼我为
        await this.listHomeReply("0001397")

        // 获取报表功能入口列表
        this.getReportList()
    },
    methods: {
        // 跳转推送率
        pushContract() {
            this.$router.push("/informationContracti")
        },
        // 跳转二维码界面
        pushQrcode() {
            this.$router.push("/qrcodeindex")
        },
        // 跳转全部用户界面
        openAllUser() {
            if (this.userNumData.userTotal && this.userNumData.userTotal > 0) {
                this.$router.push("/mobilizationregistered")
            }
        },
        // 跳转邮箱
        openEmail(todo) {
            var that = this
            if (todo.status == 3) return
            parent.layer.open({
                type: 2,
                title: todo.content,
                area: ["80%", "80%"],
                content: `/#${todo.menuUrl}?dataScopeId=${todo.dataScopeId}&houseCode=${todo.houseCode}&replierId=${todo.replierId}&userName=${todo.userName}`,
                end: function () {
                    that.listHomeReplyLatest()
                },
            })
        },
        // 跳转微心愿
        jumpWishList(todo) {
            parent.vue.goto(`${todo.menuUrl}`)
        },
        // 审核界面
        openWish(todo) {
            parent.vue.goto(`${todo.menuUrl}&status=${todo.status}`)
        },
        // 跳转你言我语菜单
        jumpHomeList(todo) {
            this.$router.push(`${todo.menuUrl || "/spokenlangindex"}?menuId=${todo.menuCode}&columnCode=${this.activeIndex == 2 ? "0001397" : "0001396"}`)
        },
        // 打开审核界面
        openHome(todo) {
            var that = this
            if (todo.status == 3) return
            parent.layer.open({
                type: 2,
                title: todo.title,
                area: ["80%", "80%"],
                content: `/#${todo.menuUrl || "/spokenlangindex"}?menuId=${todo.menuCode}&id=${todo.id}&status=${todo.status == "1" ? "answer" : todo.status == "2" ? "check" : ""}&columnCode=${
                    this.activeIndex == 2 ? "0001397" : "0001396"
                }`,
                end: function () {
                    if (that.activeIndex == 1) that.listHomeReply("0001396")
                    else if (that.activeIndex == 2) that.listHomeReply("0001397")
                },
            })
        },
        // 切换待办tab
        async changeIndex(index) {
            if (this.activeIndex == index) return
            this.activeIndex = index
            this.$Message.destroy()
            this.$Message.loading({
                background: true,
                content: "正在加载数据，请稍等...",
            })
            try {
                if (index == 0) {
                    // 待办
                    await this.getTodos()
                } else if (index == 1) {
                    // 你言我语
                    await this.listHomeReply("0001396")
                } else if (index == 2) {
                    await this.listHomeReply("0001397")
                } else if (index == 3) {
                    // 我的邮箱
                    await this.listHomeReplyLatest()
                } else if (index == 4) {
                    // 微心愿
                    await this.listHomeWishHelp()
                } else if (index === 5) {
                    this.$core.openLayerFrame({
                        type: 2,
                        title: "评论审核",
                        content: `/page#/infocomment?type=discuss&isOrg=1`,
                        area: ["100%", "100%"],
                    })
                }
                this.$Message.destroy()
            } catch (err) {
                this.$Message.destroy()
            }
        },
        // 常用功能点击事件
        changeOpen(url) {
            this.$router.push(url)
        },
        gotoOrg(org) {
            window.sessionStorage.setItem("dimensionId", org.dimensionId)
            parent.loadOrgMenu(org.orgCode)
        },
        getSysNote() {
            this.$get("/old/api/pc/information/getSysNote", {
                operatorCustGlobalId: window.sessionStorage.getItem("custGlobalId"),
                orgCode: this.orgCode,
            }).then(res => {
                //   console.log(res);
                if (res && res.data && res.data != null) {
                    this.sysnote = res.data.title + "【每日一醒】"
                }
            })
        },
        getPlatformOnline() {
            this.$get("/gateway/api/lfuser/onlineUser/getOnlineUserTotal", {}).then(res => {
                if (res.code == 200) {
                    this.platfromOnlineUserNum = res.data
                }
            })
        },
        getStat() {
            this.$get("/old/api/pc/information/getStat", {
                operatorCustGlobalId: window.sessionStorage.getItem("custGlobalId"),
                orgCode: this.orgCode,
            }).then(res => {
                //   console.log(res);
                if (res && res.dataList && res.dataList != null) {
                    this.statList = res.dataList
                    for (var i = 0; i < this.statList.length; i++) {
                        if (this.statList[i].value > 10000) {
                            this.statList[i].value = this.statList[i].value / 10000
                            this.statList[i].unit = "万"
                        }
                        var d = {
                            名称: this.statList[i].name + (this.statList[i].unit ? "（" + this.statList[i].unit + "）" : ""),
                            数量: this.statList[i].value,
                        }
                        this.chartData.rows.push(d)
                    }
                }
            })
        },
        getCommonBrowse() {
            this.$get("/old/api/pc/information/getCommonBrowse", {
                custGlobalId: window.sessionStorage.getItem("custGlobalId"),
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
            }).then(res => {
                console.log(res)
                if (res && res.dataList && res.dataList != null && res.code == 200) {
                    this.commonBrowseList = res.dataList
                }
            })
        },
        async getTodos() {
            await this.$get("/old/api/pc/information/getTodos", {
                operatorCustGlobalId: window.sessionStorage.getItem("custGlobalId"),
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: this.orgCode,
                operatorType: this.buttonRoot,
            }).then(res => {
                //  console.log(res);
                if (res && res.dataList && res.dataList != null && res.code == 200) {
                    this.todoList = res.dataList
                    this.selectList[0].num = this.todoList.length
                } else {
                    this.todoList = []
                }
            })
        },
        openEditMore(todo) {
            let content = ""
            var that = this
            if (todo && todo.functionType) {
                if (todo.functionType == "1") {
                    // 图文
                    content = `/graphiccategory`
                } else if (todo.functionType == "2") {
                    // 基本信息
                    content = `/houseingarrairsindex`
                } else if (todo.functionType == "3") {
                    // 音视频
                    content = `/newhousingaffairs`
                } else if (todo.functionType == "5") {
                    // 地图
                    content = `/mapclass`
                } else if (todo.functionType == "15") {
                    content = `/liveroommanage`
                } else if (todo.functionType == "16") {
                    content = `/voteindex`
                } else if (todo.functionType == "17") {
                    // 答题活动
                    content = `/newanswerindex`
                } else if (todo.functionType == "24") {
                    // 预约活动
                    content = `/activityindex`
                } else if (todo.functionType == "25") {
                    // 问卷活动
                    content = `/questionnaireindex`
                }
                content = "/#" + content + `?id=${todo.id}&menuId=${todo.menuCode}&status=${todo.numStatus}`
                // if(todo.functionType == '15'){
                //     content = todo.link
                // }
                if (todo.url.indexOf("/allIndex") > -1) {
                    content = `/#/allIndex?id=${todo.id}&menuId=${todo.menuCode}&status=${todo.numStatus}&functionType=${todo.functionType}&columnCode=${todo.columnCode}`
                }
                console.log("con", content)
                parent.layer.open({
                    type: 2,
                    title: todo.title,
                    area: ["80%", "80%"],
                    content,
                    end: function () {
                        that.getTodos()
                    },
                })
            }
        },
        jumpEdit(todo) {
            let content = ""
            var that = this
            if (todo && todo.functionType) {
                if (todo.functionType == "1") {
                    // 图文
                    content = `/graphiccategory`
                } else if (todo.functionType == "2") {
                    // 基本信息
                    content = `/houseingarrairsindex`
                } else if (todo.functionType == "3") {
                    // 音视频
                    content = `/newhousingaffairs`
                } else if (todo.functionType == "5") {
                    // 地图
                    content = `/mapclass`
                } else if (todo.functionType == "16") {
                    content = `/voteindex`
                } else if (todo.functionType == "17") {
                    // 答题活动
                    content = `/newanswerindex`
                } else if (todo.functionType == "24") {
                    // 预约活动
                    content = `/activityindex`
                } else if (todo.functionType == "25") {
                    // 问卷活动
                    content = `/questionnaireindex`
                } else {
                    if (todo.appCode == "suiyue") {
                        content = "/#" + todo.url
                    } else {
                        content = todo.url
                    }
                    var a = document.createElement("a")
                    a.href = content
                    a.click()
                    return
                }

                if (todo.url.indexOf("/allIndex") > -1) {
                    content = "/allIndex"
                }

                content += `?menuId=${todo.menuCode}`
                this.$router.push(content)
            }
        },
        openEdit(link, title) {
            var that = this
            parent.layer.open({
                type: 2,
                title: title,
                area: ["80%", "80%"],
                content: link,
                end: function () {
                    that.getTodos()
                },
            })
        },
        // 跳转模板引用
        jumpTemp(todo) {
            if (todo.url && todo.url.indexOf("allIndex") > -1) {
                this.$router.push(`${todo.url}?templateId=${todo.id}&functionType=${todo.functionType}&columnCode=${todo.columnCode}&menuId=${todo.menuCode}&type=1`)
                return
            }
            this.$router.push(`${this.$core.funToPage(todo)}&templateId=${todo.id}&functionType=${todo.functionType}&columnCode=${todo.columnCode}`)
        },
        getTemplate() {
            this.$get("/info/api/pc/information/template/selectLatestList", {
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffId: parent.vue.loginInfo.userinfo.id,
                page: "1",
                pageSize: "20",
            }).then(res => {
                if (res && res.dataList && res.dataList != null && res.code == 200) {
                    this.tableData = res.dataList
                }
            })
        },
        // 获取空白栏目
        getOrgColumnPvListLe(status) {
            if (status) {
                if (this.titleName == "居民提醒更新") {
                    this.$get("/statistic/api/symanage/pc/columnTree/listUpdateReminder", {
                        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    }).then(res => {
                        if (res.code == 200 && res.dataList) {
                            this.nowTime = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                            this.listData = res.dataList.map((item, index) => {
                                return {
                                    num: index + 1,
                                    ...item,
                                }
                            })

                            this.listColumns = [
                                {
                                    title: "栏目名称",
                                    key: "columnName",
                                    minWidth: 100,
                                    align: "center",
                                },
                                {
                                    title: "栏目授权机构",
                                    key: "orgNames",
                                    minWidth: 100,
                                    align: "center",
                                },
                                {
                                    title: "本周提醒次数",
                                    key: "week",
                                    minWidth: 100,
                                    align: "center",
                                },
                                {
                                    title: "累计提醒次数",
                                    key: "total",
                                    minWidth: 100,
                                    align: "center",
                                },
                            ]
                            if (this.dimensionId == 2) {
                                // 如果是居委会
                                this.listData.map(item => {
                                    if (item.columnUrl && item.columnUrl != "") {
                                        item.orgNames = item.orgName
                                    } else {
                                        item.orgNames = ""
                                    }
                                })
                            } else {
                                // 如果不是居委会
                                this.listData = this.listData.filter(item => item.columnUrl != "")
                            }

                            this.resetNum = this.listData.length
                        } else {
                            this.nowTime = ""
                        }
                    })
                } else {
                    if (this.dimensionId == 2) {
                        this.listLoading = true
                        // this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByDiOrgId", {
                        this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByOrgCodeV2", {
                            orgId: this.orgId,
                            orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                            // statLayer:5
                        }).then(res => {
                            this.listLoading = false
                            if (res.code == 200 && res.dataList) {
                                this.nowTime = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                                this.listData = res.dataList.map((item, index) => {
                                    return {
                                        num: index + 1,
                                        ...item,
                                    }
                                })
                                this.clumnNum = this.listData.length
                                this.listColumns = [
                                    {
                                        title: "栏目名称",
                                        key: "columnUrl",
                                        minWidth: 100,
                                        align: "center",
                                    },
                                    {
                                        title: "栏目授权机构",
                                        key: "orgNames",
                                        minWidth: 100,
                                        align: "center",
                                    },
                                ]
                                if (this.dimensionId == 2) {
                                    // 如果是居委会
                                    this.listData.map(item => {
                                        if (item.url && item.url != "") {
                                            item.orgNames = parent.vue.loginInfo.userinfo.orgName
                                        } else {
                                            item.orgNames = ""
                                        }
                                    })
                                } else {
                                    // 如果不是居委会
                                    this.listData = this.listData.filter(item => item.url != "")
                                }

                                if (this.buttonRoot == "1001") {
                                    this.listColumns.push({
                                        title: "操作",
                                        align: "center",
                                        width: 100,
                                        fixed: true,
                                        render: (h, params) => {
                                            return h("div", [
                                                h(
                                                    "Button",
                                                    {
                                                        style: {
                                                            marginRight: "20px",
                                                            display: params.row.functionType && params.row.functionType != "" ? "block" : "none",
                                                        },
                                                        props: {
                                                            type: "info",
                                                            size: "small",
                                                        },
                                                        on: {
                                                            click: () => {
                                                                this.$router.push(`${this.$core.funToPage(params.row)}`)
                                                            },
                                                        },
                                                    },
                                                    "前去新增"
                                                ),
                                            ])
                                        },
                                    })
                                }
                            } else {
                                this.nowTime = ""
                            }
                        })
                    }
                }
                return
            }
            if (this.dimensionId == 2) {
                this.listLoading = true
                // this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByDiOrgId", {
                this.$get("/statistic/api/symanage/pc/columnTree/selectLayerNulByOrgCodeV2", {
                    orgId: this.orgId,
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    // statLayer:5
                }).then(res => {
                    this.listLoading = false
                    if (res.code == 200 && res.dataList) {
                        this.nowTime = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                        this.listData = res.dataList.map((item, index) => {
                            return {
                                num: index + 1,
                                ...item,
                            }
                        })
                        this.clumnNum = this.listData.length

                        if (this.buttonRoot == "1001") {
                            this.listColumns = [
                                {
                                    title: "栏目名称",
                                    key: "columnUrl",
                                    minWidth: 100,
                                    align: "center",
                                },
                                {
                                    title: "栏目授权机构",
                                    key: "orgNames",
                                    minWidth: 100,
                                    align: "center",
                                },
                            ]
                            if (this.dimensionId == 2) {
                                // 如果是居委会
                                this.listData.map(item => {
                                    if (item.url && item.url != "") {
                                        item.orgNames = parent.vue.loginInfo.userinfo.orgName
                                    } else {
                                        item.orgNames = ""
                                    }
                                })
                            } else {
                                // 如果不是居委会
                                this.listData = this.listData.filter(item => item.url != "")
                            }
                            this.listColumns.push({
                                title: "操作",
                                align: "center",
                                width: 100,
                                fixed: true,
                                render: (h, params) => {
                                    return h("div", [
                                        h(
                                            "Button",
                                            {
                                                style: {
                                                    marginRight: "20px",
                                                    display: params.row.functionType && params.row.functionType != "" ? "block" : "none",
                                                },
                                                props: {
                                                    type: "info",
                                                    size: "small",
                                                },
                                                on: {
                                                    click: () => {
                                                        this.$router.push(`${this.$core.funToPage(params.row)}`)
                                                    },
                                                },
                                            },
                                            "前去新增"
                                        ),
                                    ])
                                },
                            })
                        }
                    } else {
                        this.nowTime = ""
                    }
                })
            }
            this.$get("/statistic/api/symanage/pc/columnTree/listUpdateReminder", {
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code == 200 && res.dataList) {
                    this.nowTime = this.$core.formatDate(new Date(new Date().getTime()), "yyyy-MM-dd")
                    this.listData = res.dataList.map((item, index) => {
                        return {
                            num: index + 1,
                            ...item,
                        }
                    })

                    this.listColumns = [
                        {
                            title: "栏目名称",
                            key: "columnName",
                            minWidth: 100,
                            align: "center",
                        },
                        {
                            title: "栏目授权机构",
                            key: "orgNames",
                            minWidth: 100,
                            align: "center",
                        },
                        {
                            title: "本周提醒次数",
                            key: "week",
                            minWidth: 100,
                            align: "center",
                        },
                        {
                            title: "累计提醒次数",
                            key: "total",
                            minWidth: 100,
                            align: "center",
                        },
                    ]
                    if (this.dimensionId == 2) {
                        // 如果是居委会
                        this.listData.map(item => {
                            if (item.columnUrl && item.columnUrl != "") {
                                item.orgNames = item.orgName
                            } else {
                                item.orgNames = ""
                            }
                        })
                    } else {
                        // 如果不是居委会
                        this.listData = this.listData.filter(item => item.columnUrl != "")
                    }

                    this.resetNum = this.listData.length
                } else {
                    this.nowTime = ""
                }
            })
        },
        // 获取居委会用户数据
        getUserNum() {
            this.listLoading = true
            if (this.dimensionId == 2) {
                // this.$get("/syaa/pc/sy/user/online/home/getUserByOrgId", {
                //     orgId: this.orgId,
                // }).then(res => {
                //     this.listLoading = false
                //     if (res.code == 200) {
                //         this.userNumData = res.data
                //     }
                // })

                this.$get("/gateway/api/symanage/pc/homepage/statHomePageInfo", {
                    orgCode: parent.vue.loginInfo.userinfo.communityCode,
                }).then(res => {
                    this.listLoading = false
                    if (res.code == 200) {
                        this.coreData = res.data
                    }
                })
            }
        },
        // 获取微心愿接口
        async listHomeWishHelp() {
            await this.$get("/datamsg/api/finger/wishHelp/listHomeWishHelp", {
                type: "1",
                status: "1",
                orgId: this.orgId,
            }).then(res => {
                if (res.data && res.code && res.code == 200) {
                    this.wishList = res.data.dataList
                    this.selectList[4].num = res.data.dataSize
                } else {
                    this.wishList = []
                    this.selectList[4].num = 0
                    this.$Message.error({
                        content: "获取微心愿数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取我的邮箱接口
        async listHomeReplyLatest() {
            await this.$get("/sendmsg/pc/chat/communityMessage/listHomeReplyLatest", {
                orgId: this.orgId,
            }).then(res => {
                if (res.data && res.code && res.code == 200) {
                    this.replyList = res.data.replyListRos
                    this.selectList[3].num = res.data.replyCount
                } else {
                    this.replyList = []
                    this.selectList[3].num = 0
                    this.$Message.error({
                        content: "获取我的邮箱数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
        // 获取你言我语接口
        async listHomeReply(columnCode) {
            this.homeList = []
            await this.$get("/info/api/pc/eventIssue/listHomeReply", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                oemCode: parent.vue.oemInfo.oemCode,
                columnCode,
            }).then(res => {
                if (res.data && res.code && res.code == 200) {
                    this.homeList = res.data.replyList

                    console.log("this.homeList", this.homeList)
                    if (columnCode == "0001396") {
                        this.selectList[1].num = res.data.replyCount || 0
                    } else if (columnCode == "0001397") {
                        this.selectList[2].num = res.data.replyCount || 0
                    }
                } else {
                    if (columnCode == "0001396") {
                        this.homeList = []
                        this.selectList[1].num = 0
                        this.$Message.error({
                            content: "获取你言我语接口数据失败",
                            background: true,
                        })
                        return
                    } else {
                        this.selectList[2].num = 0
                        this.$Message.error({
                            content: "获取民呼我为接口数据失败",
                            background: true,
                        })
                        return
                    }
                }
            })
        },

        // 获取评论审核
        async getPinglun() {
            await this.$get("/gateway/syinfopublish/api/pc/infoComments/listComments", {
                infoType: "INFORMATION",
                excludeOneself: 2,
                status: 1,
                orgCodeSelf: window.sessionStorage.getItem("orgCode"),
                orgCode: "",
            }).then(res => {
                if (res && res.code && res.code == 200) {
                    this.selectList[5].num = res.dataList.length
                }
            })
        },
        //查看栏目清单
        getClumnList(name) {
            if (name && name != "") {
                this.titleName = "居民提醒更新"
                this.titleNum = this.resetNum
                if (this.resetNum == 0) return
            } else {
                this.titleName = "空白栏目清单"
                this.titleNum = this.clumnNum
                if (this.clumnNum == 0) return
            }
            this.listStatus = true
            this.getOrgColumnPvListLe(true)
        },
        changeListStatus(val) {
            this.listStatus = val
        },

        /**
         * 获取报表功能入口列表
         */
        getReportList() {
            const orgCode = parent.vue.loginInfo?.userinfo?.orgCode

            if (!orgCode) return

            this.$get("/gateway/api/fastDev/AppForm/findPageByOrgCode", {
                orgCode: orgCode,
                page: 1,
                pageSize: 9,
                busiCategory: "DYTX",
            }).then(res => {
                if (res.code == 200) {
                    this.reportList = res.dataList
                }
            })
        },

        /**
         * 待办相关接口
         */
        // 获取管理待审核条数
        async getHomeAudit() {
            return this.$get("/syaa/pc/sy/user/online/home/getFamilyMemberCount", {
                communityCode: parent.vue.loginInfo?.userinfo?.communityCode,
                status: 1,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                inviterFlag: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.homeAudit = res.data
                }
            })
        },
        // 获取补充房号
        async getSupplementRoomNumber() {
            const userinfo = parent.vue.loginInfo?.userinfo
            return this.$get("/syaa/pc/sy/user/online/home/selectFamilyMemberReplenishRooNo", {
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: userinfo.communityCode,
                operCustGlobalId: userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this.supplementRoomNumber = res.data
                }
            })
        },
        // 获取实名审核数量
        async getUserAuthCount() {
            const userinfo = parent.vue.loginInfo?.userinfo

            const communitys = await this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                staffId: userinfo.id,
                orgCode: userinfo.orgCode,
            })

            const projects = communitys.dataList.filter(c => c.code === userinfo.communityCode)

            if (projects.length > 0) {
                this.projectId = projects[0].id
                this.$get("/old/api/pc/userAuth/getUserAuthCount", {
                    projectId: this.projectId,
                    orgCode: userinfo.orgCode,
                    staffId: userinfo.custGlobalId,
                    status: 0,
                }).then(res => {
                    if (res.code == 200) {
                        this.userAuthCount = res.data
                    }
                })
            }
        },
        /**
         * 获取议事厅待办数据
         */
        getAgendaroomChamberCount() {
            return this.$get("/gateway/syinfopublish/api/app/chamber/getChamberPendingCount", {
                orgCode: parent.vue.loginInfo.userinfo.communityCode,
            }).then(res => {
                if (res.code != 200) return
                this.agendaroomAdvice = res.data.adviceCount
                this.agendaroomCommons = res.data.commonsCount
            })
        },

        /**
         * 获取机构统计
         */
        getInstitutionalStatistics() {
            if (this.dimensionId == 2) return

            this.$get("/gateway/sy-user/sy-user/pc/syuser/selectByOrgAnalyze", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code != 200) return
                this.orgUserTotal = res.data.total
            })

            this.$get("/gateway/api/symanage/pc/orgInfoPublish/selectOrgCount", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            }).then(res => {
                if (res.code != 200) return
                this.orgPublishTotal = res.data
            })
        },
    },
    filters: {
        spliceTitle(option) {
            if (option.length > 9) {
                return (option = option.slice(0, 9) + "...")
            } else {
                return option
            }
        },
    },
}
</script>

<style lang="less" scoped>
body {
    background: #f9f9f9 !important;
}
.tablediv {
    width: 100%;
    .ivu-table-header {
        display: none !important;
    }
}
.numList {
    height: 158px;
    display: flex;
    margin: 8px 8px 0 0;
    box-sizing: border-box;
    justify-content: space-between;
    /deep/.ivu-card-bordered {
        width: 25%;
        .ivu-card-body {
            width: 100%;
            box-sizing: border-box;
            // padding: 18px;
            text-align: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .num {
                word-wrap: break-word;
                word-break: normal;
                font-size: 22px;
                font-weight: 600;
                line-height: 40px;
            }
            .title {
                font-size: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
}
.userItem {
    /deep/.ivu-card-body {
        padding: 0 16px;
    }
}
/deep/.ivu-table td {
    border-bottom: 1px solid #f5f5f5;
    .ivu-table-cell {
        padding: 0px;
        button {
            float: right;
        }
    }
}
/deep/.tablediv {
    button {
        span {
            color: rgb(36, 200, 200);
        }
    }
}
.select-view {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    .select-item {
        margin-right: 10px;
        padding: 5px 10px;
        border-radius: 10px;
        user-select: none;
        cursor: pointer;
        border: 1px solid rgba(#ccc, 0.5);
        color: #333;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        .span-select {
            color: red;
        }
    }
    .active {
        background: #2faaf7;
        color: #fff;
        border: none;
    }
}
.num-title {
    margin: 20px 10px 10px 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hint {
        display: flex;
        align-items: center;

        .name {
            font-size: 14px;
            margin-left: 5px;
            color: #888;
        }
    }
}

.module-box {
    display: flex;
    margin: 8px 8px 8px 0;

    .item-box {
        flex: 1;
        padding: 10px 5px;
        margin-right: 7px;
        box-sizing: border-box;
        border: 1px solid #e8eaec;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        min-width: 120px;
        background: #fff;

        &:last-child {
            margin-right: 0;
        }

        .name {
            font-size: 13px;
            color: #888;
            margin-bottom: 10px;
            text-align: center;
        }

        .increase-box {
            width: 100%;
            padding: 0 5px;
            box-sizing: border-box;

            .item {
                margin-top: 10px;
                font-size: 13px;
                color: #777;
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 16px;

                .val {
                    font-size: 14px;
                    padding: 0 5px;
                    color: #333;
                    font-weight: 500;

                    &.click {
                        text-decoration: underline;
                        color: #19be6b;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.statistics-box {
    display: flex;
    justify-content: space-between;
    margin: 8px 8px 8px 0;

    .item-box {
        width: calc(50% - 5px);
        padding: 15px 10px;
        box-sizing: border-box;
        border: 1px solid #e8eaec;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        line-height: 1;

        .title {
            font-size: 15px;
            color: #888;
            margin-right: 10px;
        }

        .value {
            font-size: 20px;
            font-weight: bold;
        }
    }
}

// 待办
.todo-box {
    .head-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: bold;
        }
    }

    .item-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: 5px;

        .item {
            cursor: pointer;
            border: 1px solid #ededed;
            border-radius: 6px;
            padding: 8px 20px;
            margin: 7px 7px 0 0;
            display: flex;
            align-items: center;
            line-height: 1;

            .value {
                margin-left: 5px;
                color: red;
            }
        }

        .empty {
            width: 100%;
            height: 60px;
            align-items: center;
            justify-content: center;
            color: #999;
            font-size: 16px;
            display: none;

            &:first-child {
                display: flex;
            }

            span {
                margin-left: 5px;
                font-size: 13px;
            }
        }
    }
}

// 报表功能
.report-card {
    .head-box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .title {
            font-size: 16px;
            font-weight: bold;
        }

        .more {
            cursor: pointer;
            font-size: 13px;
            color: #888;
        }
    }

    .functions-box {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;

        > .item-box {
            cursor: pointer;
            width: calc(33.33% - 20px / 3);
            margin: 5px 10px 5px 0;
            padding: 0 10px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #fff;
            color: #03b696;
            border-radius: 6px;
            border: 1px solid #f3f3f3;
            box-sizing: border-box;

            &:nth-child(3n) {
                margin-right: 0;
            }

            .name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        > .empty-box {
            width: 100%;
            padding: 80px 20px;
            box-sizing: border-box;
            text-align: center;
        }
    }
}
</style>
